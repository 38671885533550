import { AllBaseConfig, AreaConfig, LineConfig, Plot } from "@ant-design/plots";
import { useTheme } from "@emotion/react";
import { Button as Btn, Tooltip } from "antd";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { MapExploratoryInput } from "components/MapExploratoryInput";
import Spinner from "components/Spinner";
import { Text } from "components/Text";
import { PlanModalContext } from "components/provider/PlanModalProvider";
import { GroupedExploratoryOption } from "hooks/useGeoExploratory";
import { GraphTooltipValue, PlotData } from "hooks/useTimeSeriesData";
import { useUserData } from "hooks/useUser";
import { ResponsiveContext } from "lib/context";
import {
  getExploratoryMap,
  getNameForCode,
  isExploratoryScore,
} from "lib/helpers/exploratoryHelpers";
import { inProximity } from "lib/helpers/graphHelpers";
import { TimeSeriesConfig } from "lib/options/timeseriesDatapointConfig";
import { maxBy, meanBy, minBy } from "lodash";
import moment from "moment";
import dynamic from "next/dynamic";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import { useWindowSize } from "react-use";
import { Geo } from "types/MapContext";
import { CombinedDatapoints, Datapoints, ScoreDatapoints } from "types/cube";
import { usePostHog } from "posthog-js/react";
import { hasAccessToPremiumArea } from "lib/helpers/userHelpers";
import { DownloadOutlined } from "@ant-design/icons";
import { useRouter } from "next/router";
import { ReportDownloadSuccess } from "./ReportDownloadSuccess";
import DownloadGraph from "./DownloadGraph";
import { formatterMapType, FormatType } from "lib/options/formatterMap";
import SecondaryLineSwitch from "./SecondaryLineSwitch";
import AreaGraph from "./AreaGraph";

const Line = dynamic(() => import("@ant-design/plots/es/components/line"), {
  ssr: false,
});
const Area = dynamic(() => import("@ant-design/plots/es/components/area"), {
  ssr: false,
});

const avgDataPoints = [
  "home_value_to_med_hh_income_ratio",
  Datapoints.HOUSE_PAYMENT_RATE_OF_MED_INCOME,
  Datapoints.TOTAL_ACTIVE_INVENTORY,
  Datapoints.SHARE_OF_LISTINGS_WITH_PRICE_CUT,
  Datapoints.HOME_VALUE_TO_EARNINGS_RATIO,
  Datapoints.HOME_VALUE_TO_ANNUAL_RENT_RATIO,
  Datapoints.DAYS_ON_MARKET,
  Datapoints.HOME_VALUE_FIVE_YEAR_GROWTH_RATE,
];

const GraphCard = ({
  geo,
  info,
  plotData,
  subPlotData,
  datapoint,
  showFeedbackPopup,
  groupedExploratoriesWithScoreData,
  handleExploratoryChange,
  exploratoryConfig,
  showYearly,
  showSecondaryLine,
  loading,
  tooltipValues,
  subTooltipValues,
  primaryFormatter,
  secondaryFormatter,
  monthlyPlotData,
  yearlyPlotData,
  setShowSecondaryLine,
}: {
  geo: Geo;
  info: any;
  plotData: PlotData[];
  subPlotData: PlotData[];
  datapoint: CombinedDatapoints;
  showFeedbackPopup: any;
  groupedExploratoriesWithScoreData: GroupedExploratoryOption[];
  handleExploratoryChange: any;
  exploratoryConfig: TimeSeriesConfig;
  showYearly: boolean;
  showSecondaryLine: boolean;
  setShowSecondaryLine: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  tooltipValues?: GraphTooltipValue;
  subTooltipValues?: GraphTooltipValue;
  primaryFormatter: (
    value: number,
    decimals?: number,
    notation?: string,
  ) => string;
  secondaryFormatter?: (value: number) => string;
  monthlyPlotData: PlotData[];
  yearlyPlotData: PlotData[];
}) => {
  const theme = useTheme();
  const user = useUserData();
  const areaRef = useRef<Plot<AllBaseConfig>>();
  const posthog = usePostHog();
  const { setShowPlanModal, setPlanPopupTrigger } =
    useContext(PlanModalContext);
  const { isTabletOrMobile } = useContext(ResponsiveContext);
  const { height: windowHeight } = useWindowSize();
  const router = useRouter();
  const [downloadSuccess, setDownloadSuccess] = useState(false);

  const [annotationTooltipConfig, setAnnotationTooltipConfig] = useState({
    open: false,
    top: 0,
    left: 0,
  });

  const tooltipRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        annotationTooltipConfig.open &&
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target as Node)
      ) {
        setAnnotationTooltipConfig({ open: false, top: 0, left: 0 });
      }
    };
    if (annotationTooltipConfig.open) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [annotationTooltipConfig]);

  const isMonthlyGraphShown = exploratoryConfig?.hasMonthly && !showYearly;
  const isScore = isExploratoryScore(datapoint);
  const isOverUnderValued =
    datapoint === Datapoints.OVER_UNDER_VALUED_PERCENTAGE;
  const homeValue5YearGrowth =
    datapoint === Datapoints.HOME_VALUE_FIVE_YEAR_GROWTH_RATE;
  const salaryToAffordHouse = datapoint === Datapoints.SALARY_TO_AFFORD_HOUSE;
  const homeValeGrowthYoY = datapoint === Datapoints.HOME_VALUE_GROWTH_RATE;
  const buyVsRentPercentage =
    datapoint === Datapoints.BUY_VERSUS_RENT_PERCENTAGE;

  const capRate = datapoint === Datapoints.CAP_RATE;
  const homeValue = datapoint === Datapoints.HOME_VALUE;
  const republican = datapoint === Datapoints.VOTING_REPUBLICAN_PERCENTAGE;
  const democrat = datapoint === Datapoints.VOTING_DEMOCRAT_PERCENTAGE;

  const renderGraph = () => {
    const pointsWithNoHistoricData = [];
    const pointsWithNoHistoricDataForZips = [
      Datapoints.TOTAL_MIGRATION,
      Datapoints.MIGRATION_PERCENTAGE,
    ];

    if (
      //pointsWithNoHistoricData.includes(datapoint as Datapoints) ||
      (geo === Geo.ZIP &&
        pointsWithNoHistoricDataForZips.includes(datapoint as Datapoints)) ||
      (plotData.length < 4 && !loading)
    ) {
      return {
        error: true,
        errorMessage:
          "Not enough historical data to graph timeseries for this Datapoint.",
      };
    }
    let subPlotDataCalculated = subPlotData;
    if (buyVsRentPercentage && tooltipValues) {
      const formattedEntries = Object.entries(tooltipValues).map(
        (item, tooltipIndex) => {
          const data = { date: item[0], label: 0 as any };
          item[1].forEach((tooltipRow) => {
            if (tooltipRow.label === "Date") {
              data["label"] = plotData[tooltipIndex].label || 0;
            } else {
              data[tooltipRow.label] =
                parseFloat(tooltipRow.value.replace(/[\$,]/g, "")) / 12;
            }
          });
          return data;
        },
      );
      const MonthlyAppartmentRent = formattedEntries.map((item) => {
        return {
          date: item.date,
          label: item.label,
          value: item["Yearly Apartment Rent"],
          type: "Monthly Apartment Rent",
        };
      });
      const MonthlyHousePayment = formattedEntries.map((item) => {
        return {
          date: item.date,
          label: item.label,
          value: item["Typical Yearly House Payment"],
          type: "Typical Monthly House Payment",
        };
      });
      subPlotDataCalculated = [
        ...MonthlyAppartmentRent,
        ...MonthlyHousePayment,
      ];
      plotData.map((item, index) => {
        plotData[index].value =
          MonthlyHousePayment[index].value - MonthlyAppartmentRent[index].value;
      });
    }
    const graphWrapperStyles = {};
    const plotHeight = Math.max(windowHeight * 0.55, 450);
    const primaryMaxY = maxBy(plotData, (value) => value.value)?.value!;
    const primaryMinY = minBy(plotData, (value) => value.value)?.value!;
    const secondaryMaxY = maxBy(subPlotDataCalculated, (value) => value.value)
      ?.value!;
    const secondaryMinY = minBy(subPlotDataCalculated, (value) => value.value)
      ?.value!;
    const maxY = Math.max(
      primaryMaxY,
      secondaryMaxY ? secondaryMaxY : primaryMaxY,
    );
    const minY = Math.min(
      primaryMinY,
      secondaryMinY ? secondaryMinY : primaryMinY,
    );

    var paddingPercentage = 0.1;
    var dataRange = maxY - minY;
    var padding = paddingPercentage * dataRange;
    var yMinLimit = minY - padding;
    const formatType = formatterMapType[datapoint].primaryFormatter;
    if (yMinLimit < 0 || formatType === FormatType.Percentage) {
      yMinLimit = minY;
    }
    var yMaxLimit = maxY + padding;
    const values = plotData.map((pd) => pd.value);
    const highestIndex = values.indexOf(Math.max(...values));
    const lowestIndex = values.indexOf(Math.min(...values));

    let maxInPlotData: number = Math.max(...plotData.map((data) => data.value));
    let maxInSubPlotData: number = Math.max(...subPlotData.map((data) => data.value));

    if(democrat || republican) {
      maxInPlotData = plotData.length > 0 ? plotData.reduce((max, current) => new Date(current.date) > new Date(max.date) ? current : max).value : maxInPlotData;
      maxInSubPlotData = subPlotData.length > 0 ? subPlotData.reduce((max, current) => new Date(current.date) > new Date(max.date) ? current : max).value : maxInSubPlotData;
    }

    // @ts-ignore
    const areaConfig: AreaConfig = {
      data: plotData,
      xField: "label",
      yField: "value",
      appendPadding: [32, 16, 32, 16],
      theme: {
        defaultColor: theme.colors.seaGreen,
      },
      height: plotHeight,
      areaStyle: () => {
        return {
          fill: `l(270) 0:#ffffff 1:${theme.colors.seaGreen}`,
          fillOpacity: 0.15,
          cursor: "pointer",
        };
      },
      line: { color: theme.colors.seaGreen },
      label: {
        formatter: (text, _, index) => {
          const label = primaryFormatter(Number(text.value));
          if (areaRef.current) {
            const filteredDataLength = areaRef.current.chart.getData().length;
            const modBy = Math.floor(filteredDataLength / 20) + 1;
            return index % modBy === 0 || index === filteredDataLength - 1
              ? label
              : "";
          }
          return label;
        },
        position: "top",
        style: {
          fontSize: 11,
          fill: isTabletOrMobile ? "" : "black",
        },
      },
      yAxis: {
        label: {
          formatter: (text) => primaryFormatter(Number(text)),
          style: {
            fill: "black",
          },
        },
        grid: {
          line: {
            style: {
              stroke: "#E4E4E4D9",
              lineDash: [4, 2],
            },
          },
        },
        min: democrat || republican ? 0 : yMinLimit,
        max: democrat || republican ? 100 : yMaxLimit,
      },
      xAxis: {
        label: {
          formatter: (text) =>
            isMonthlyGraphShown ? moment(text).format("YYYY") : text,
          autoRotate: true,
          style: {
            fill: "black",
          },
        },
        tickInterval: isMonthlyGraphShown
          ? 12
          : isTabletOrMobile
          ? 2
          : undefined,
      },
      point: {
        shape: "circle",
        size: plotData.length < 60 ? 6 : 2,
        style: {
          fill: theme.colors.seaGreen,
          stroke:
            isTabletOrMobile && isMonthlyGraphShown
              ? theme.colors.seaGreen
              : theme.colors.white,
        },
      },
      // @ts-ignore
      annotations:
        isScore ||
        isOverUnderValued ||
        datapoint === Datapoints.SALARY_TO_AFFORD_HOUSE
          ? [
              isScore && {
                type: "line",
                start: isScore ? ["min", "50"] : ["min", "20"],
                end: isScore ? ["max", "50"] : ["max", "20"],
                style: {
                  stroke: "#949494",
                  lineDash: [3, 2],
                  lineWidth: 2,
                },
              },
              ...(datapoint === ScoreDatapoints.HOME_PRICE_MOMENTUM_SCORE ||
              datapoint === Datapoints.SALARY_TO_AFFORD_HOUSE ||
              isOverUnderValued
                ? [
                    {
                      type: "html",
                      position: showYearly ? ["75%", -2] : ["80%", 1],
                      html: `
                      <div class="score-graph-annotation-top">
                         ${(() => {
                           if (isScore) {
                             return "Sellers Market";
                           } else if (
                             !isScore &&
                             datapoint === Datapoints.SALARY_TO_AFFORD_HOUSE
                           ) {
                             return "Salary to Afford House";
                           } else if (
                             !isScore &&
                             datapoint !== Datapoints.SALARY_TO_AFFORD_HOUSE
                           ) {
                             return "Overvalued";
                           }
                         })()}
                      </div>
                    `,
                    },
                    {
                      type: "html",
                      position: showYearly ? ["75%", "90%"] : ["75%", 89],
                      html: `
                      <div class="score-graph-annotation-bottom">
                      ${isScore ? "Buyers Market" : "Undervalued"}
                      </div>
                    `,
                    },
                  ]
                : []),
            ]
          : avgDataPoints.includes(datapoint)
          ? [
              {
                type: "line",
                start: ["min", "mean"],
                end: ["max", "mean"],
                style: {
                  stroke: "#000",
                  lineDash: [3, 2],
                  lineWidth: 2,
                },
              },
              {
                type: "line",
                start: ["min", "mean"],
                end: ["max", "mean"],
                style: {
                  stroke: "#fff0",
                  lineWidth: 15,
                },
              },
              {
                type: "line",
                start: ["min", "mean"],
                end: ["max", "mean"],
                style: {
                  stroke: "#fff0",
                  lineWidth: 15,
                },
              },
            ]
          : [],
      tooltip: {
        shared: false,
        customContent: (title, data) => {
          const hovered = data[0]?.data;
          return isTabletOrMobile
            ? `<div>
            Date: ${hovered?.label}
            <br>
            <br>
            ${getNameForCode(datapoint)}: ${primaryFormatter(
              Number(hovered?.value || 0),
            )}
            </div>`
            : `
            <div>
              ${tooltipValues?.[hovered?.date]
                ?.map((item) => `<b>${item.label}</b>: ${item.value}`)
                .join("<br><br>")} 
            </div`;
        },
        domStyles: {
          "g2-tooltip": {
            padding: "11px",
            backgroundColor: "black",
            color: "white",
            textAlign: "left",
          },
        },
      },

      interactions: [
        {
          type: "tooltip",
          cfg: {
            start: [
              {
                trigger: "plot:mousemove",
                isEnable(context) {
                  return inProximity(context);
                },
                action: "tooltip:show",
              },
            ],

            end: [
              {
                trigger: "plot:mouseleave",
                action: ["tooltip:hide"],
              },
              {
                trigger: "plot:mousemove",
                isEnable(context) {
                  return inProximity(context, true);
                },
                action: "tooltip:hide",
              },
            ],
          },
        },
      ],
      onReady: (plot) => {
        let mouseEntered = false;
        let tooltipTimeout: NodeJS.Timeout | null = null;

        plot.on("annotation-line:click", (e) => {
          mouseEntered = true;
          if (mouseEntered) {
            setAnnotationTooltipConfig((pre) => {
              return { open: true, left: e.gEvent.x, top: e.gEvent.y };
            });
          } else {
            setAnnotationTooltipConfig((pre) => {
              return { open: false, left: 0, top: 0 };
            });
          }
        });

        plot.on("annotation-line:mouseenter", (e) => {
          mouseEntered = true;

          if (mouseEntered) {
            setAnnotationTooltipConfig((pre) => {
              return { open: true, left: e.gEvent.x, top: e.gEvent.y };
            });
          }
        });

        plot.on("annotation-line:mouseleave", (e) => {
          mouseEntered = false;
          if (tooltipTimeout) {
            clearTimeout(tooltipTimeout);
            tooltipTimeout = null;
          }

          setTimeout(() => {
            setAnnotationTooltipConfig({
              open: false,
              left: 0,
              top: 0,
            });
          }, 100);
        });
      },
      slider: {
        height: 8,
        textStyle: { opacity: 0 },
        backgroundStyle: { opacity: 1, fill: theme.colors.brightGray },
        foregroundStyle: { opacity: 0.7, fill: theme.colors.primary },
        handlerStyle: {
          height: 18,
          width: 18,
          radius: 9,
          stroke: 0,
          fill: theme.colors.primary,
          highLightFill: theme.colors.primary,
        },
      },
      onEvent(chart, event) {
        if (!user.canAccessPremium) {
          if (event.type == "slider:mouseenter") {
            const graphContainer = document.querySelector(".graph-container");

            const bar: HTMLDivElement =
              document.querySelector(".graph-slider-bar") ||
              document.createElement("div");

            bar.className = "graph-slider-bar";

            bar.style.cssText = `
              width: 95%;
              height: 20px;
              background-color: rgba(0,0,0,0);
              position: absolute;
              bottom: 12px;
              left: 50px;
              cursor: pointer;
              `;

            const tooltip: HTMLDivElement =
              document.querySelector(".graph-slider-tooltip") ||
              document.createElement("div");

            tooltip.className = "graph-slider-tooltip";
            tooltip.innerHTML = `<span>To use slider, upgrade to premium plan</span>`;
            tooltip.style.cssText = `
                position: absolute;
                bottom: -40px;
                left: 50%;
                background: ${theme.colors.primary};
                border-radius: 6px;
                padding: 4px 6px;
                transform: translate(-50%, 0);
                color: ${theme.colors.white};
                font-size: 16px;
                font-weight: 700;
              `;

            const clickEvent = () => {
              setShowPlanModal(true);
              setPlanPopupTrigger("Change graph time slider");
            };
            const mouseLeaveEvent = () => {
              bar.removeEventListener("mouseleave", mouseLeaveEvent);
              bar.removeEventListener("click", clickEvent);
              bar?.remove();
            };

            bar.addEventListener("mouseleave", mouseLeaveEvent);
            bar.addEventListener("click", clickEvent);

            graphContainer?.appendChild(bar);
            bar?.appendChild(tooltip);
          }
        }
      },
    };

    // @ts-ignore
    const lineConfig: LineConfig = {
      data: plotData,
      lineStyle: {
        lineWidth: 2, // Set the line thickness here
      },
      xField: "label",
      yField: "value",
      seriesField: "type",
      // @ts-ignore
      sizeField: "type",
      legend: false,
      appendPadding: [32, 16, 32, 16],
      height: plotHeight,
      color: ({ type }) => {
        if((democrat && type === "primary") || (republican && type === "secondary")){
          return theme.colors.blue;
        }
        if((republican && type === "primary") || (democrat && type === "secondary")){
          return theme.colors.venetianRed;
        }
        if (type === "primary") {
          return theme.colors.seaGreen;
        }
        if (type === "mar") {
          return theme.colors.red;
        }
        if (type === "mhp") {
          return theme.colors.blue;
        }
        return theme.colors.gray;
      },
      label: {
        formatter: (text, _, index) => {
          if (text.type == "secondary") return "";

          const label = primaryFormatter(Number(text.value));
          if (areaRef.current) {
            const filteredDataLength = areaRef.current.chart
              .getData()
              .filter((item) => item.type === "primary").length;
            const modBy = Math.floor(filteredDataLength / 20) + 1;
            return index % modBy === 0 || index === filteredDataLength - 1
              ? label
              : "";
          }
          return label;
        },
        position: "top",
        style: {
          fontSize: 11,
          fill: isTabletOrMobile ? "" : "black",
        },
      },
      yAxis: {
        label: {
          formatter: (text) => primaryFormatter(Number(text)),
          style: {
            fill: "black",
          },
        },
        grid: {
          line: {
            style: {
              stroke: "#E4E4E4D9",
              lineDash: [4, 2],
            },
          },
        },
        min: democrat || republican ? 0 : yMinLimit,
        max: democrat || republican ? 100 : yMaxLimit,
      },
      xAxis: {
        label: {
          formatter: (text) =>
            isMonthlyGraphShown ? moment(text).format("YYYY") : text,
          autoRotate: true,
          style: {
            fill: "black",
          },
        },
        tickInterval: isMonthlyGraphShown
          ? 12
          : isTabletOrMobile
          ? 2
          : undefined,
      },
      point: {
        shape: ({ type }) => {
          if (["mar", "mhp", "primary"].includes(type)) {
            return "circle";
          }
          return "";
        },
        size: ({ type }) => {
          if (type === "primary") {
            return plotData.length < 60 ? 6 : 2;
          }
          return plotData.length < 60 ? 4 : 2;
        },
        color: ({ type }) => {
          if((democrat && type === "primary") || (republican && type === "secondary")){
            return theme.colors.blue;
          }
          if((republican && type === "primary") || (democrat && type === "secondary")){
            return theme.colors.venetianRed;
          }
          if (type === "primary") {
            return theme.colors.seaGreen;
          }
          if (type === "mar") {
            return theme.colors.red;
          }
          if (type === "mhp") {
            return theme.colors.blue;
          }
          return theme.colors.gray;
        },
      },
      // @ts-ignore
      annotations: salaryToAffordHouse
        ? [
            {
              type: "html",
              position: showYearly ? ["50%", -2] : ["50%", 1],
              html: `
                     ${
                       maxInPlotData >= maxInSubPlotData
                         ? `
                      <div class="score-graph-annotation-top">
                        Salary to Afford House
                      </div>                      
                    `
                         : `
                      <div class="score-graph-annotation-top grey-top-background">
                        Median Income
                      </div>

                    `
                     }
                    `,
            },
            {
              type: "html",
              position: showYearly ? ["60%", "86%"] : ["60%", 89],
              html: `
                      ${
                        maxInPlotData >= maxInSubPlotData
                          ? `
                      <div class="score-graph-annotation-bottom grey-background">
                        Median Income
                      </div>                      
                    `
                          : `
                      <div class="score-graph-annotation-bottom-green">
                        Salary to Afford House
                      </div>

                    `
                      }
                    `,
            },
          ]
        : homeValue && showSecondaryLine
        ? [
            {
              type: "html",
              position: showYearly ? ["70%", -2] : ["70%", 1],
              html: `
                     ${
                       maxInPlotData >= maxInSubPlotData
                         ? `
                      <div class="score-graph-annotation-top">
                        Home Value
                      </div>                      
                    `
                         : `
                      <div class="score-graph-annotation-top grey-top-background">
                        Fair Home Value
                      </div>

                    `
                     }
                    `,
            },
            {
              type: "html",
              position: showYearly ? ["70%", "86%"] : ["70%", 89],
              html: `
                      ${
                        maxInPlotData >= maxInSubPlotData
                          ? `
                      <div class="score-graph-annotation-bottom grey-background">
                       Fair Home Value
                      </div>                      
                    `
                          : `
                      <div class="score-graph-annotation-bottom-green">
                        Home Value
                      </div>

                    `
                      }
                    `,
            },
          ]
        : homeValeGrowthYoY && showSecondaryLine
        ? [
            {
              type: "html",
              position: showYearly ? ["50%", -10] : ["50%", 5],
              html: `
                     ${
                       maxInPlotData >= maxInSubPlotData
                         ? `
                      <div class="score-graph-annotation-top">
                        Home Value Growth YoY
                      </div>                      
                    `
                         : `
                      <div class="score-graph-annotation-top grey-top-background">
                        CPI Inflation YoY
                      </div>

                    `
                     }
                    `,
            },
            {
              type: "html",
              position: showYearly ? ["70%", "86%"] : ["70%", 89],
              html: `
                      ${
                        maxInPlotData >= maxInSubPlotData
                          ? `
                      <div class="score-graph-annotation-bottom grey-background">
                       CPI Inflation YoY
                      </div>                      
                    `
                          : `
                      <div class="score-graph-annotation-bottom-green">
                        Home Value Growth YoY
                      </div>

                    `
                      }
                    `,
            },
          ]
        : capRate && showSecondaryLine
        ? [
            {
              type: "html",
              position: showYearly ? ["50%", -10] : ["50%", 5],
              html: `
                     ${
                       maxInPlotData >= maxInSubPlotData
                         ? `
                      <div class="score-graph-annotation-top">
                        Cap Rate
                      </div>                      
                    `
                         : `
                      <div class="score-graph-annotation-top grey-top-background">
                        10-Year Treasury Yield
                      </div>

                    `
                     }
                    `,
            },
            {
              type: "html",
              position: showYearly ? ["70%", "86%"] : ["70%", 89],
              html: `
                      ${
                        maxInPlotData >= maxInSubPlotData
                          ? `
                      <div class="score-graph-annotation-bottom grey-background">
                       10-Year Treasury Yield
                      </div>                      
                    `
                          : `
                      <div class="score-graph-annotation-bottom-green">
                        Cap Rate
                      </div>

                    `
                      }
                    `,

                  },
    ] : (democrat) ? [
            {
              type: "html",
              position: showYearly ? ["70%", -2] : ["70%", 1],
              html: `
                     ${
                  maxInPlotData >= maxInSubPlotData
                      ? `
                      <div class="score-graph-annotation-top-blue">
                        Democrat
                      </div>                      
                    `
                      : `
                      <div class="score-graph-annotation-top-red red-top-background">
                        Republican
                      </div>

                    `
              }
                    `,
            },
            {
              type: "html",
              position: showYearly ? ["70%", "86%"] : ["70%", 89],
              html: `
                      ${
                  maxInPlotData >= maxInSubPlotData
                      ? `
                      <div class="score-graph-annotation-bottom red-background">
                       Republican
                      </div>                      
                    `
                      : `
                      <div class="score-graph-annotation-bottom-blue">
                        Democrat
                      </div>

                    `
              }
                    `,
            },
          ]
          : (republican) ?
              [
                {
                  type: "html",
                  position: showYearly ? ["70%", -2] : ["70%", 1],
                  html: `
                     ${
                      maxInPlotData >= maxInSubPlotData
                          ? `
                      <div class="score-graph-annotation-top-red">
                        Republican
                      </div>                      
                    `
                          : `
                      <div class="score-graph-annotation-top-blue blue-top-background">
                        Democrat
                      </div>

                    `
                  }
                    `,
                },
                {
                  type: "html",
                  position: showYearly ? ["70%", "86%"] : ["70%", 89],
                  html: `
                      ${
                      maxInPlotData >= maxInSubPlotData
                          ? `
                      <div class="score-graph-annotation-bottom blue-background">
                       Democrat
                      </div>                      
                    `
                          : `
                      <div class="score-graph-annotation-bottom-red">
                        Republican
                      </div>

                    `
                  }
                    `,
                },
              ]
              :[],
      // @ts-ignore

      tooltip: {
        shared: false,
        customContent: (title, data) => {
          const hovered = data[0]?.data;
          if (hovered?.type == "primary") {
            return isTabletOrMobile
              ? `<div>
            Date: ${hovered?.label}
            <br>
            <br>
            ${getNameForCode(datapoint)}: ${primaryFormatter(
              Number(hovered?.value || 0),
            )}
            </div>`
              : `
            <div>
              ${tooltipValues?.[hovered?.date]
                ?.map((item) => `<b>${item.label}</b>: ${item.value}`)
                .join("<br><br>")} 
              </div>`;
          } else {
            return isTabletOrMobile
              ? `<div>
              Date: ${hovered?.label}
              <br>
              <br>
              ${getNameForCode(datapoint)}: ${primaryFormatter(
                Number(hovered?.value || 0),
              )}
              </div>`
              : `
              <div>
                ${subTooltipValues?.[hovered?.date]
                  ?.map((item) => `<b>${item.label}</b>: ${item.value}`)
                  .join("<br><br>")} 
              </div>`;
          }
        },
        domStyles: {
          "g2-tooltip": {
            padding: "11px",
            backgroundColor: "black",
            color: "white",
            textAlign: "left",
          },
        },
      },
      interactions: [
        {
          type: "tooltip",
          cfg: {
            start: [
              {
                trigger: "plot:mousemove",
                isEnable(context) {
                  return inProximity(context);
                },
                action: "tooltip:show",
              },
            ],

            end: [
              {
                trigger: "plot:mouseleave",
                action: ["tooltip:hide"],
              },
              {
                trigger: "plot:mousemove",
                isEnable(context) {
                  return inProximity(context, true);
                },
                action: "tooltip:hide",
              },
            ],
          },
        },
      ],
      onReady: (plot) => {
        let mouseEntered = false;
        let tooltipTimeout: NodeJS.Timeout | null = null;

        plot.on("annotation-line:mouseenter", (e) => {
          mouseEntered = true;

          tooltipTimeout = setTimeout(() => {
            if (mouseEntered) {
              setAnnotationTooltipConfig((pre) => {
                return { open: true, left: e.gEvent.x, top: e.gEvent.y };
              });
            }
          }, 1000); // 1 seconds delay
        });

        plot.on("annotation-line:mouseleave", (e) => {
          mouseEntered = false;
          if (tooltipTimeout) {
            clearTimeout(tooltipTimeout);
            tooltipTimeout = null;
          }

          setTimeout(() => {
            setAnnotationTooltipConfig({
              open: false,
              left: 0,
              top: 0,
            });
          }, 100);
        });
      },
      slider: {
        height: 8,
        textStyle: { opacity: 0 },
        backgroundStyle: { opacity: 1, fill: theme.colors.brightGray },
        foregroundStyle: { opacity: 0.7, fill: theme.colors.primary },
        handlerStyle: {
          height: 18,
          width: 18,
          radius: 9,
          stroke: 0,
          fill: theme.colors.primary,
          highLightFill: theme.colors.primary,
        },
      },
      onEvent(chart, event) {
        if (!user.canAccessPremium) {
          if (event.type == "slider:mouseenter") {
            const graphContainer = document.querySelector(".graph-container");

            const bar: HTMLDivElement =
              document.querySelector(".graph-slider-bar") ||
              document.createElement("div");

            bar.className = "graph-slider-bar";

            bar.style.cssText = `
              width: 95%;
              height: 20px;
              background-color: rgba(0,0,0,0);
              position: absolute;
              bottom: 12px;
              left: 50px;
              cursor: pointer;
              `;

            const tooltip: HTMLDivElement =
              document.querySelector(".graph-slider-tooltip") ||
              document.createElement("div");

            tooltip.className = "graph-slider-tooltip";
            tooltip.innerHTML = `<span>To use slider, upgrade to premium plan</span>`;
            tooltip.style.cssText = `
                position: absolute;
                bottom: -40px;
                left: 50%;
                background: ${theme.colors.primary};
                border-radius: 6px;
                padding: 4px 6px;
                transform: translate(-50%, 0);
                color: ${theme.colors.white};
                font-size: 16px;
                font-weight: 700;
              `;

            const clickEvent = () => {
              setPlanPopupTrigger("Change graph time slider");
              setShowPlanModal(true);
            };
            const mouseLeaveEvent = () => {
              bar.removeEventListener("mouseleave", mouseLeaveEvent);
              bar.removeEventListener("click", clickEvent);
              bar?.remove();
            };

            bar.addEventListener("mouseleave", mouseLeaveEvent);
            bar.addEventListener("click", clickEvent);

            graphContainer?.appendChild(bar);
            bar?.appendChild(tooltip);
          }
        }
      },
    };

    let antPlotConfig = areaConfig;
    if (
      salaryToAffordHouse ||
      (homeValue && showSecondaryLine) ||
      (homeValeGrowthYoY && showSecondaryLine) ||
      (buyVsRentPercentage && showSecondaryLine) ||
      (capRate && showSecondaryLine) ||
      (republican) ||
      (democrat)
    ) {

      //@ts-ignore
      lineConfig.data = [
        ...(buyVsRentPercentage
          ? [...subPlotDataCalculated]
          : [
              ...plotData.map((d) => ({ ...d, type: "primary" })),
              ...subPlotDataCalculated.map((d) => ({
                ...d,
                type: "secondary",
              })),
            ]),
      ];
      antPlotConfig = lineConfig;
    }

    return {
      graphWrapperStyles,
      antPlotConfig,
      plotData,
    };
  };

  const {
    antPlotConfig,
    error: graphError,
    errorMessage: graphErrorMessage,
    graphWrapperStyles,
  } = useMemo(() => {
    return renderGraph();
  }, [datapoint, geo, info, isTabletOrMobile, plotData]);

  const memoizedGraph = useMemo(() => {
    if (loading || !antPlotConfig) {
      return <div></div>;
    } else if (graphError) {
      return <></>; //we can show the retry button here to trigger fetch call again if the initial graph fails
    } else if (salaryToAffordHouse || (homeValue && showSecondaryLine) || (homeValeGrowthYoY && showSecondaryLine) || (capRate && showSecondaryLine) || (republican) || (democrat)) {
      return (
        <div
          id="1212"
          className="graph-container"
          css={{ position: "relative" }}
        >
          <Line
            css={(theme) => ({ background: theme.colors.white })}
            chartRef={(r) => {
              if (r) areaRef.current = r;
            }}
            {...antPlotConfig!}
            style={{ maxWidth: "95%" }}
          />
        </div>
      );
    } else if (buyVsRentPercentage) {
      return (
        <div
          id="1212"
          className="graph-container"
          css={{ position: "relative" }}
        >
          <AreaGraph
            areaConfig={antPlotConfig}
            plotData={plotData}
            isMonthly={isMonthlyGraphShown}
            datapoint={datapoint}
          />
        </div>
      );
    } else {
      return (
        <div
          id="1212"
          className="graph-container"
          css={{ position: "relative" }}
        >
          <Area
            css={(theme) => ({ background: theme.colors.white })}
            chartRef={(r) => {
              if (r) areaRef.current = r;
            }}
            {...antPlotConfig!}
            style={{ maxWidth: "95%" }}
          />
        </div>
      );
    }
  }, [antPlotConfig, loading]);
  const userCanAccess =
    user.canAccessPremium ||
    hasAccessToPremiumArea(
      user,
      { state_code: info.state_code, geo_code: info.geo_code },
      false,
      geo,
    );
  const computeData = (data, geoName) => {
    const formatted_data = data.map((item) => {
      const newItem = { date: item.label };

      newItem["datapoint"] = geoName?.toString();
      newItem[`${geoName || datapoint}`] = primaryFormatter(
        item.value,
        2,
        "standard",
      );
      if (geo === Geo.ZIP) {
        newItem["Zip Code"] = info.GEOID.toString();
      } else {
        newItem["Geolocation"] = (info.FULLNAME || info.NAME).toString();
      }
      return newItem;
    });
    return formatted_data;
  };
  const getDownlaodReportData = () => {
    const geoName = !!getExploratoryMap(datapoint)?.name
      ? getExploratoryMap(datapoint)?.name
      : (datapoint as string);
    let yearlyFormatted = [];
    const monthlyFormatted = computeData(
      exploratoryConfig?.hasMonthly ? monthlyPlotData : plotData,
      geoName,
    );
    if (yearlyPlotData.length > 0) {
      yearlyFormatted = computeData(yearlyPlotData, geoName);
    }
    return [monthlyFormatted, yearlyFormatted];
  };
  const [monthlyReportData, yearlyReportData] = getDownlaodReportData();
  const isReportUnvailable =
    graphError || loading || !antPlotConfig || monthlyReportData.length === 0;
  const downloadButton = (
    <div>
      {!userCanAccess || isReportUnvailable ? (
        <Btn
          type="primary"
          css={{
            background: theme.colors.black,
            borderColor: theme.colors.black,
          }}
          disabled={isReportUnvailable}
          icon={<DownloadOutlined />}
          onClick={() => {
            if (!userCanAccess) {
              setPlanPopupTrigger("Download Graph Data");
              setShowPlanModal(true);
            }
          }}
        ></Btn>
      ) : (
        <DownloadGraph
          geo={geo}
          info={{ ...info, ...exploratoryConfig }}
          downloadReportDataMonthly={monthlyReportData}
          downloadReportDataYearly={yearlyReportData}
          isMonthlyActive={!showYearly}
          onDownloadSuccess={() => {
            setTimeout(() => {
              setDownloadSuccess(true);
            }, 300);
          }}
        />
      )}
    </div>
  );

  return (
    <Flex
      direction="column"
      flex={1.5}
      css={{
        border: `1px solid rgba(0,0,0,0.2)`,
        backdropFilter: "blur(25px)",
        padding: `20px 0`,
        width: "100%",
      }}
    >
      <ReportDownloadSuccess
        open={downloadSuccess}
        onClose={() => setDownloadSuccess(false)}
      />
      <MapExploratoryInput
        exploratory={datapoint}
        geo={geo}
        downloadButton={downloadButton}
        zipCode={info?.NAME || info?.name}
        handleExploratoryChange={handleExploratoryChange}
        hideLabel
        overrideOptions={groupedExploratoriesWithScoreData}
        info={info}
      />
      {datapoint === Datapoints.HOME_VALUE && (
        <SecondaryLineSwitch
          title={"Show Fair Home Value"}
          showSecondaryLine={showSecondaryLine}
          setShowSecondaryLine={setShowSecondaryLine}
        />
      )}
      {datapoint === Datapoints.HOME_VALUE_GROWTH_RATE && (
        <SecondaryLineSwitch
          title={"CPI Inflation YoY"}
          showSecondaryLine={showSecondaryLine}
          setShowSecondaryLine={setShowSecondaryLine}
        />
      )}
      {datapoint === Datapoints.CAP_RATE && (
        <SecondaryLineSwitch
          title={"10-Year Treasury Yield"}
          showSecondaryLine={showSecondaryLine}
          setShowSecondaryLine={setShowSecondaryLine}
        />
      )}
      <div style={{ minWidth: "100%" }}>
        {!graphError && (
          <div
            style={{
              minWidth: "100%",
              minHeight: isTabletOrMobile ? 100 : 400,
              position: "absolute",
              zIndex: 0,
            }}
          >
            <Spinner />
          </div>
        )}
        <div
          css={(theme) => ({
            width: "100%",
            textAlign: "center",
            background: theme.colors.white,
          })}
        >
          {graphError ? (
            <>
              <Text large align="center">
                {graphErrorMessage}
              </Text>
              <div
                css={{
                  marginTop: "1.5rem",
                  textAlign: "center",
                }}
              >
                <Button
                  onClick={() =>
                    showFeedbackPopup({
                      placeholder: "Let us know your request...",
                      subject: "TimeSeries request",
                    })
                  }
                  variant="alternate"
                >
                  CONTACT US
                </Button>
              </div>
            </>
          ) : (
            <div
              css={() => ({
                ...graphWrapperStyles,
              })}
            >
              {plotData!?.length > 0 && (
                <div css={{ position: "relative" }}>
                  {annotationTooltipConfig.open && !isScore && (
                    <Tooltip
                      title={
                        <div>
                          Average:{" "}
                          {secondaryFormatter
                            ? secondaryFormatter(
                                meanBy(plotData, (value) => value.value),
                              )
                            : primaryFormatter(
                                meanBy(plotData, (value) => value.value),
                              )}
                        </div>
                      }
                      open={true}
                      getPopupContainer={() => {
                        const container = document.getElementById("1212");
                        return container ? container : document.body;
                      }}
                    >
                      <div
                        ref={tooltipRef}
                        css={{
                          position: "absolute",
                          top: annotationTooltipConfig.top,
                          left: annotationTooltipConfig.left,
                        }}
                      />
                    </Tooltip>
                  )}
                  {memoizedGraph}
                </div>
              )}
              {datapoint === Datapoints.OVER_UNDER_VALUED_PERCENTAGE &&
                (plotData![0]?.label as number) > 2005 && (
                  <p>
                    <small>
                      The data only goes back to {plotData![0].label} and is not
                      complete. As such could be prone to errors in data.
                    </small>
                  </p>
                )}
            </div>
          )}
        </div>
      </div>
    </Flex>
  );
};

export default GraphCard;
